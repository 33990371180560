<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import router from "@/router"
import { ref, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2";


export default {
  page: {
    title: "Add User",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout, PageHeader, Multiselect, ValidationProvider, ValidationObserver
  },
  data() {
    return {
      title: "Add User",
       select_label: "Select"
    };
  },
  setup() {
     const { $get } = useHttp()
     const { $post } = useHttp()
     const masterRef = ref(null)

   let userLogin = JSON.parse(localStorage.getItem('user'))
    const userType = userLogin.usertype

     const LOV = ref({
       userType: [],
       userRole: [],
       customer: [],
       vendor: [],
       ports: [],

     })
    //  const userType = ref()
    //  const customer = ref()
    //  const vendor = ref()
    //  const port = ref()

    const getUserType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/UTY'})
      LOV.value.userType = data
    }

      const getCustomer = async () => { 
      const {data} = await $get({ url: 'master/customers'})
      LOV.value.customer = data

      }

      const getVendor = async () => { 
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendor = data

      }

      const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }
    
      const getUserRole = async () => {
      const {data} = await $get({ url: 'master/userrole'})
      LOV.value.userRole = data
    }


      const form = ref({
      email: '',
      name: '',
      address:'',
      phone:'',
      password:'',
      userType: '',
      userRole:'',
      customer: '',
      vendor:'',
      ports:'',
      
      })

      const validationForm = async () => {

         if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){

        let dataSubmit = {
        email : form.value.email,
        usrFullname : form.value.name,
        usrAddress : form.value.address,
        usrPhone : form.value.phone,
        password : form.value.password,
        usertypeCode : form.value.userType.lbc_code,
        // userRole : form.value.userrole.url_id,
        customerId : form.value.customer.id,
        vendorId : form.value.vendor.id,
        portId : form.value.ports.id,
         } 
         
         if(form.value.userType.lbc_code === 'UTYCUS'){
         dataSubmit.customerId = form.value.customer.id
          }
         if(form.value.userType.lbc_code === 'UTYVEN'){
         dataSubmit.vendorId = form.value.vendor.id
          }

      $post({
        url: 'master/users/user-add',
        data: dataSubmit
      })
       masterRef.value.reset();
      router.push({ name: 'appsmd-user-list' })
       masterRef.value.reset(); 
      }
     }
    }

     const validationDetail = ()=> {
     if(
       form.value <1 ||
       form.value === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }


    onMounted(() => {
     getCustomer()
     getPorts()
     getVendor()
     getUserType()
     getUserRole()
    })


    return {
      LOV,
      form,
      validationForm,
      getCustomer,
      getPorts,
      getVendor,
      getUserType,
      getUserRole,
      required,
      masterRef,
      userType
      // customer,
      // userType,
      // vendor,
      // port,
    }

  },

   methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
             <validation-observer ref="masterRef">
                <b-form @submit.prevent>
            <div class="row">
              <div class="col-12">
                  <div class="form-group mb-3">
                        <label>Email</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.email"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Name</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.name"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Address</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="address"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.address"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Phone</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="phone"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.phone"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                  <div class="form-group mb-3">
                        <label>Password</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="password"
                        rules="required"
                        >
                        <b-form-input id="" name="" value="" v-model="form.password"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>User Type</label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="user type"
                        rules="required"
                        >
                        <multiselect v-model="form.userType" :options="LOV.userType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                       <div class="form-group mb-3 hidden">
                        <label>User Role</label><br />
                        <multiselect v-model="form.userRole" :options="LOV.userRole" label="url_name" track-by="url_name" :select-label="select_label" ></multiselect>
                        </div>
                       <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Customer</label><br />
                        <validation-provider v-if = "form.userType.lbc_code === 'UTYCUS' "
                        #default="{ errors }"
                        name="customer"
                        rules="required"
                        >
                        <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>                        
                        <validation-provider v-else
                        >
                        <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        </validation-provider>                        
                      </div>
                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Vendor</label><br />
                        <validation-provider v-if = "form.userType.lbc_code === 'UTYVEN' "
                        #default="{ errors }"
                        name="vendor"
                        rules="required"
                        >
                        <multiselect v-model="form.vendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                         <validation-provider v-else
                        >
                         <multiselect v-model="form.vendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label" ></multiselect>
                        </validation-provider> 
                      </div>
                      <div v-if  = "userType === 'UTYINL' " class="form-group mb-3">
                        <label>Port</label><br />
                        <!-- <validation-provider
                        #default="{ errors }"
                        name="customer"
                        rules="required"
                        > -->
                        <!-- <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect> -->
                          <multiselect id="mv_port" name="mv_port" :select-label="select_label" :custom-label="customLabel" v-model="form.ports" :options="LOV.ports"></multiselect>
                        <!-- <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                      </div>
              </div>
            </div>
             </b-form>
              </validation-observer>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'appsmd-user-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>